import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import axios from 'axios'
import logowhite from './assets/milpalavras.logo.gif'
import logoblack from './assets/milpalavras.logo.neg.gif'
import loadingImg from './assets/carregando.66kb.gif'
import manda from './assets/mandapalavra.gif'
import mini from './assets/minihistorias.gif'
import palavra from './assets/palavrapuxaoutra.gif'
import down from './assets/down-arrow.svg'
import { useMount, useRaf } from 'react-use'

const removeAccents = (str:string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');


function App() {
  const [textSize, setTextSize] = useState(0)
  const [loading, setLoading] = useState(false)
  const [saiba, setSaiba] = useState(false)
  const [word, setWord] = useState(null as any)
  const [bckword, setBckWord] = useState(null as any)
  const [modal, setModal] = useState(false)
  const [focus,setFocus] = useState(false)
  const [allWords, setAllWords] = useState<any[]>([])
  const [list, setList] = useState<any[]>([])
  const [input, setInput] = useState('' as any)
  const [number, setNumber] = useState(0)
  const [hover, setHover] = useState(false)
  const [theme, setTheme] = useState('light' as any)
  const [first, setFirst] = useState(true)
  const [firstFocus, setFirstFocus] = useState(true)
  const [hoverWord, setHoverWord] = useState('' as any)
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;
  const changeWord = (number: number) => {

    setLoading(true)
    setFocus(false)
    setHover(false)
    fetch(`https://api.projeto1000palavras.com.br/word/${number}`).then(async (res) => {
      const json = await res.json()
      setWord(json.word)
      setInput('')
      setList(json.list)
      setLoading(false)
    })

  }

  const emailInput = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (emailInput.current) {
      const input = emailInput.current;
      input.setSelectionRange(0, 10);
    }
  }, []);

  const refInput = useRef(null);
  useMount(() => {
    setLoading(true)
    fetch('https://api.projeto1000palavras.com.br/random').then(async (res) => {
      const json = await res.json()
      setWord(json.word[0])
      setList(json.list)
      setInput(json.word[0].word)
      fetch('https://api.projeto1000palavras.com.br/words').then(async (res) => {
        const json = await  res.json()
        setAllWords(json)
        setLoading(false)
      })      
    }) 
  })

  const [posY, setPosY] = useState(0)
  const [posX, setPosX] = useState(0)

  useEffect(() => {
    if (refInput.current) {
      const input: any = refInput.current;
      setPosY(input.getBoundingClientRect().y+input.getBoundingClientRect().height+80)
    }
  }, [refInput.current]);

  const [inputSize, setInputSize] = useState(1)
  useEffect(() => {
    if (refInput.current) {
      const input: any = refInput.current;
      const length = word.word.length      
      setInputSize(word.word.length) 
      setPosX(35)
      if (length > 10) {
        setTextSize(10)
        setPosX(length*4.5)
      }else{
        if (length > 8) setPosX(45)
        setTextSize(0)
      }
    }
  },[word])



  if (loading || word === null) {
    return (
      <div className='w-full h-screen overflow-x-hidden animate-pulse flex justify-center items-center'>
        <p className='text-4xl'>
          <img src={loadingImg} alt="Carregando" />
        </p>
      </div>
    )
  }
   return (
    
    <div className={`App w-full overflow-hidden min-h-[100vh] p-4 lg:p-2 relative ${theme === 'light' ? 'bg-white text-black' : 'bg-black text-white'}`}>      
        <div className={`modal  bg-[rgba(255,255,255,0.9)] w-full h-[100vh] fixed ${!modal ? 'top-[-100vh]': 'top-0'} transition-all left-0 z-50 flex flex-col justify-start items-center p-3`}>
          <div className='w-full flex flex-row lg:hidden justify-end items-center'>
            <p onClick={() => setModal(!modal)} className='flex items-center justify-center cursor-pointer'>Fechar</p>
          </div>
          <div className='font-bold my-6 lg:my-0 text-xl lg:text-2xl p-3'>
            <img src={theme === 'light' ? logowhite: logoblack} alt='logo' className='w-36 lg:w-36' />
          </div>          
          <div className='flex flex-col justify-center items-center gap-10'>
            {/*list.map((item) => (
              <p onClick={()=> changeWord(item.number)} className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>{item.word} <span className='text-sm absolute text-black '>{item.number}</span></p>
            ))*/}
              <p className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>Educação</p>
              <p className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>Arte</p>
              <p className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>Games</p>
              <p className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>Conteúdo</p>
              <p className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>Produtos</p>
              <p className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>Lab</p>
              <p className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>Compartilhar</p>
          </div>
        </div>
        <div className='w-full flex flex-row lg:hidden justify-between items-center'>
          <p onClick={() => {
              if (theme === 'light')
                setTheme('dark')
              else
                setTheme('light')
            }} className={`${theme === 'light' ? 'bg-black' : 'bg-white'} cursor-pointer rounded-full w-3 h-3`}></p>
          <p onClick={() => setModal(!modal)} className={`${modal ? 'hidden' : 'flex'} items-center justify-center cursor-pointer`}>Menu</p>
        </div>
        <div className='w-full lg:max-w-[90vw] xl:max-w-[90vw] mx-auto flex flex-col lg:flex-row justify-between items-center'>
          <div className='font-bold my-6 lg:my-0 text-xl lg:text-2xl p-3'>
            <img src={theme === 'light' ? logowhite: logoblack} alt='logo' className='w-36 lg:w-36' />
          </div>          
          <div className='lg:flex hidden lg:flex-row grid my-4 lg:my-0 grid-cols-2 justify-start lg:items-center gap-5 lg:justify-center lg:gap-7'>
            {/*list.map((item) => (
              <p onClick={()=> changeWord(item.number)} className='hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl text-[#959595] relative mr-8'>{item.word} <span className='text-sm absolute text-black '>{item.number}</span></p>
            ))*/}
            <p className={`hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl ${theme === 'light' ? 'text-[#959595]': 'text-white'} relative mr-8`}>Educação</p>
            <p className={`hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl ${theme === 'light' ? 'text-[#959595]': 'text-white'} relative mr-8`}>Arte</p>
            <p className={`hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl ${theme === 'light' ? 'text-[#959595]': 'text-white'} relative mr-8`}>Games</p>
            <p className={`hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl ${theme === 'light' ? 'text-[#959595]': 'text-white'} relative mr-8`}>Conteúdo</p>
            <p className={`hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl ${theme === 'light' ? 'text-[#959595]': 'text-white'} relative mr-8`}>Produtos</p>
            <p className={`hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl ${theme === 'light' ? 'text-[#959595]': 'text-white'} relative mr-8`}>Lab</p>
            <p className={`hover-underline-animation hover:text-black cursor-pointer font-semibold text-xl lg:text-xl ${theme === 'light' ? 'text-[#959595]': 'text-white'} relative mr-8`}>Compartilhar</p>
            <p onClick={() => {
              if (theme === 'light')
                setTheme('dark')
              else
                setTheme('light')
            }} className={`${theme === 'light' ? 'bg-black' : 'bg-white'} cursor-pointer rounded-full w-3 h-3`}></p>
          </div>

        </div>
        <div className="w-full p-2 lg:p-4 lg:mt-8 items-start lg:max-w-[90vw] xl:max-w-[90vw] mx-auto justify-end flex flex-col lg:min-h-[90vh]">
            <div className='mb-20'>
              <p className='border-0 lg:mb-0 mb-0 text-xl lg:text-2xl text-[#cccccc] w-full outline-0 '>Digite uma palavra</p>
              {focus && 
                  <div style={{
                    top: `${posY}px`,
                  }} className='fixed w-ful lg:w-auto text-gray-500 gap-3 flex flex-col z-50 text-5xl'>
                      {allWords.filter(item => item.word.toLocaleLowerCase() !== word.word && removeAccents(item.word.toLocaleLowerCase()).indexOf(removeAccents(input.toLocaleLowerCase()))>-1).slice(0,5).map((word) => (                        
                        <p onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={(e)=> { console.log(word); e.preventDefault(); changeWord(word.number)}} className='cursor-pointer hover-underline-animation hover:text-black'>{word.word}</p>
                      ))}
                  </div>
                }
              <div ref={refInput} style={{                
              }} className='lg:text-[10vw] max-w-[90vw] overflow-hidden text-[10ch] pt-0 mt-0 padding-b-[-20px] border-black flex flex-row justify-start relative'>
                {focus &&
                  <div className={`${focus && 'flex w-[100vw] justify-center items-center fixed top-0 left-0 z-30 h-[100vh] bg-[rgba(255,255,255,0.9)]'}`}>
                  </div>
                }
                <input 
                className="bg-transparent z-40"
                  ref={(input) => {
                    if (input && first) {
                      input.selectionStart = input.selectionEnd = 0;
                      input.focus();
                      setFirst(false)
                    }
                   } }
                  onClick={() => {
                    setWord((prev:any) => {
                      return {
                        ...prev,
                        word: ''
                      }
                    })
                    if (!firstFocus) {
                      setFirstFocus(false)
                      setInput('')
                    }
                  }}
                  title='Digite uma palavra'
                  onFocus={()=>{
                    setBckWord(word.word)
                    setFocus(true)
                    setWord((prev:any) => {
                      return {
                        ...prev,
                        word: ''
                      }
                    })                    
                    if (!firstFocus) {
                      setFirstFocus(false)
                      setInput('')
                    }
                    if (firstFocus) setFirstFocus(false)
                  }}
                  onBlur={()=>{
                    if (!hover)
                      setFocus(false)
                    setWord((prev:any) => {
                      return {
                        ...prev,
                        word: bckword
                      }
                    })
                  }}
                style={{
                  outline: 'none',
                  fontSize: `${textSize > 0 && '8vw'}`,
                }} onChange={(e) => {
                  setInput(e.target.value)
                }} type='text' value={!focus ? word.word : input}/>
                <div className='absolute h-[0.8px] bg-black w-full lg:w-full bottom-2 lg:bottom-8 2xl:bottom-16'></div>
                <div style={{
                  left: `${isMobile ? 75 : posX-2}vw`,
                  top:`${isMobile ? 10 : 30}px`,
                }} className='absolute text-red-500 w-full min-w-[20vw] font-bold text-xl xl:text-5xl'>
                  <span className='w-[10vw] absolute lg:top-0 z-20'>n.{word.number}</span>
                </div>
                
              </div>              
            </div>            
            <div style={{lineHeight: '0.3'}} className='sacramento flex flex-col  opacity-20 text-2xl xl:text-5xl mb-20 w-full lg:w-9/12 mt-[-120px]'>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptate consequuntur ducimus minima eos necessitatibus consectetur autem numquam totam quas suscipit harum voluptatibus sunt natus deserunt nisi, explicabo quia doloribus unde! consequuntur ducimus minima eos necessitatibus consectetur autem numquam totam quas suscipit harum voluptatibus sunt natus deserunt nisi, explicabo quia doloribus unde!</p>
            </div>
            <div className='flex w-full gap-6 lg:w-[100%] max-w-[100vw] flex-col lg:flex-row justify-between items-start'>
              <div className='flex flex-col text-md lg:w-5/12  lg:text-md justify-center items-start'>
                {word['Significado'] !=='' && 
                  <>
                    <p className='font-bold capitalize'>Significados</p>
                    <p style={{ wordBreak: 'break-word'}} className='font-light' dangerouslySetInnerHTML={{__html:word['Significado']}}></p>
                  </>
                }
                {word['Sinonimos'] !=='' && 
                  <>
                    <p className='font-bold mt-2'>Sinônimos</p>
                    <p style={{ wordBreak: 'break-word'}} className='font-light break-words' dangerouslySetInnerHTML={{__html:word['Sinonimos']}}></p>
                  </>
                }

              </div>
              {!saiba && 
                <p onClick={() => setSaiba(true)} className='font-bold lg:hidden my-6 flex flex-row justify-center items-center'>
                  Saiba mais
                  <img src={down} alt="" className='w-6 h-6 ml-2 inline-block' />
                </p>
              }
              <div className={`${saiba ? 'flex' : 'hidden'} lg:flex transition-all flex-col max-w-[100vw] lg:mb-20  text-md lg:w-5/12 lg:text-md justify-center items-start relative`}>
                {hoverWord !== '' &&
                  (
                    <img className='absolute top-0 mb-20 hover-0 w-full' src={hoverWord === 'manda' ? manda : hoverWord==='mini' ? mini : palavra} alt="" />
                  )
                }
                {word['Antonimos'] !=='' && 
                  <>
                    <p className='font-bold mt-2'>Antônimos</p>
                    <p style={{ wordBreak: 'break-word'}} className='font-light' dangerouslySetInnerHTML={{__html:word['Antonimos']}}></p>
                  </>
                }                
                {word['Etimologia'] !=='' && 
                <>
                  <p className={`${saiba ? 'flex' : 'hidden'} lg:block font-bold mt-2`}>Etimologia</p>
                  <p style={{ wordBreak: 'break-word'}} className={`${saiba ? 'flex' : 'hidden'} lg:block font-light`} dangerouslySetInnerHTML={{__html:word['Etimologia']}}></p>       
                </>
                }
                {word['ClasseGramatical'] !=='' &&                
                <>
                  <p className='font-bold mt-2'>Classe gramatical</p>
                  <p style={{ wordBreak: 'break-word'}} className='font-light'dangerouslySetInnerHTML={{__html:word['ClasseGramatical']}}></p>
                </>
                }
                 {word['Separacao'] !=='' &&                
                <>
                  <p className='font-bold mt-2'>Formas nominais do verbo:</p>
                  <p style={{ wordBreak: 'break-word'}} className='font-light'dangerouslySetInnerHTML={{__html:word['Separacao']}}></p>
                </>
                }
                {/*<p className='font-bold mt-2'>Definição</p>
                <p style={{ wordBreak: 'break-word'}} className='font-light'dangerouslySetInnerHTML={{__html:word['Definição']}}></p>
                <p className='font-bold'>Separação</p>
              <p style={{ wordBreak: 'break-word'}} className='font-light'dangerouslySetInnerHTML={{__html:word['Separacao']}}></p>|*/}
              {word['TipoSentenca']!==undefined && word['Numafrase']!== '' &&
              <>
                <p className='font-bold mt-2'>Em {word['TipoSentenca'] !== "LIVRO" ? `uma ${(word['TipoSentenca']).toLocaleLowerCase()}` : 'um livro'}:</p>
                <p style={{ wordBreak: 'break-word'}} className='font-light'dangerouslySetInnerHTML={{__html:word['Numafrase']}}></p>
              </>
              }
                
              </div>
              <div className='lg:flex flex-col mt-5 lg:mt-0 text-md text-white hidden lg:w-2/10 -z-0 lg:text-md justify-center items-start'>
                <a style={{
                  backgroundImage: 'url('+manda+')',
                  backgroundPosition: 'center',
                  boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.6)'
                }} href="#" className='font-bold w-full cursor-pointer px-2 py-10 mb-2'>
                  Mande essa palavra
                </a>
                <a href="#" style={{
                  backgroundImage: 'url('+mini+')',
                  backgroundPosition: 'center',
                  boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.6)'
                }} /*onMouseLeave={() => setHoverWord('')} onMouseOver={() => setHoverWord('mini')} */ className='font-bold w-full cursor-pointer px-2 min-w-[400px] py-10 mb-2'>
                  Mini histórias
                </a>
                <a href="#" style={{
                  backgroundImage: 'url('+palavra+')',
                  backgroundPosition: 'center',
                  boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.6)'
                }} /* onMouseLeave={() => setHoverWord('')} onMouseOver={() => setHoverWord('palavra')} */ className='font-bold w-full cursor-pointer px-2 py-10 mb-2'>
                  Uma palavra puxa outra
                </a>
              </div>
              <div className='flex flex-col mt-5 lg:mt-0 text-white text-md w-full lg:hidden -z-0 lg:text-md justify-center items-start'>
                <a style={{
                  backgroundImage: 'url('+manda+')',
                  backgroundPosition: 'center',
                  boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.6)'
                }} href="#" className='font-bold w-full cursor-pointer px-2 py-10 mb-2'>
                  Mande essa palavra
                </a>
                <a style={{
                  backgroundImage: 'url('+mini+')',
                  backgroundPosition: 'center',
                  boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.6)'
                }} href="#" className='font-bold w-full cursor-pointer px-2 py-10 mb-2'>
                  Mini histórias
                </a>
                <a style={{
                  backgroundImage: 'url('+palavra+')',
                  backgroundPosition: 'center',
                  boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.6)'
                }} href="#" className='font-bold w-full cursor-pointer px-2 py-10 mb-2'>
                  Uma palavra puxa outra
                </a>
              </div>
            </div>
        </div>
      
    </div>
  );
}

export default App;
